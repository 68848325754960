// UserInfoPage.tsx
import { UserAllLoginDetailData, PagedResponse } from "../../types/userTypes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import React from "react";
import PaginationByServer from "../PagenationByServer";
import { getFormattedDate, getHighlightStyle, isOutsideWorkingHours, isWeekend } from "./userUtils";
interface UserDetailFormProps {
  pagedLogs: PagedResponse<UserAllLoginDetailData> | null;
  handlePageChange: (pageNumber: number) => void; // 페이지 번호 변경 함수
  handleResetList: () => void; // 사원 목록 리셋 함수
}
const UserDetailForm: React.FC<UserDetailFormProps> = ({ pagedLogs,handlePageChange,handleResetList }) => {
  

  console.log("userDetailForm페이지 렌더링");

  if (!pagedLogs) {
    return <div>데이터가 없습니다.</div>;
  }
  if (!Array.isArray(pagedLogs.content)) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ width: "100%", minWith: "200px" }}>
        <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Time
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  IP address
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedLogs.content.map((user: UserAllLoginDetailData, index) => {
                const formattedDate = getFormattedDate(user.loginDate);
                const isWeekendLogin = isWeekend(user.loginDate);
                const isOutsideHours = isOutsideWorkingHours(user.loginTime);
                const highlightStyle = getHighlightStyle(
                  isWeekendLogin,
                  isOutsideHours
                ); 
                return (
                  <TableRow
                    key={`${user.userId}-${user.loginDate}-${user.loginTime}-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" sx={highlightStyle}>
                      {formattedDate}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.loginTime}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.loginType}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.pcIp}
                    </TableCell>
                  </TableRow>
                )
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          // justifyContent="flex-end"
          // flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box />

          {pagedLogs ? (
            <PaginationByServer
              pagedUnits={pagedLogs}
              handlePageChange={handlePageChange}
            />
          ) : (
            ""
          )}

          <Button
            variant="contained"
            onClick={handleResetList}
            sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
          >
            사원 목록
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserDetailForm;
