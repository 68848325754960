/* eslint-disable import/no-anonymous-default-export */

import mainRoutes from "./MainRoutes";
import managerRoutes from "./managerRoutes";
import userRoutes from "./userRoutes";
import technicalBoardRoutes from "./technicalBoardRoutes";
import PaymentBoardRoutes from "./PaymentBoardRoutes";
import OrderRoutes from "./OrderRoutes";
import IntroduceRoutes from "./IntroduceRoutes";
import DashBoardRoutes from "./DashBoardRoutes";
export default [
  ...mainRoutes,
  ...managerRoutes,
  ...userRoutes,
  ...technicalBoardRoutes,
  ...PaymentBoardRoutes,
  ...OrderRoutes,
  ...IntroduceRoutes,
  ...DashBoardRoutes
];
