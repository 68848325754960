import ManagerListPage from "../pages/admin/managerListPage";
import SignInPage from "../pages/manager/signInPage";
import {SignUpPage} from "../pages/manager/signUpPage";
import { ManagerUpdatePage } from "../pages/manager/managerUpdatePage";
import FindIdPage from "../pages/manager/findIdPage";

const managerRoutes = [
  {
    path: "/sign-up",
    component: SignUpPage,
  },
  {
    path: "/sign-in",
    component: SignInPage,
  },
  {
    path: "/manager-list",
    component:  ManagerListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/admin-list",
    component:  ManagerListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/manager-update",
    component:  ManagerUpdatePage,
    allowedRoles: ["0","1"],
  },
  {
    path: "/manager-update/:id",
    component:  ManagerUpdatePage,
    allowedRoles: ["1"],
  },
  {
    path: "/find-manager-id",
    component:  FindIdPage,
  },




];

export default managerRoutes;
