// userUtils.ts

// 요일을 반환하는 함수
export const getFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);

  // 날짜 옵션 설정
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    weekday: "short", // 'short', 'long', 'narrow' 중 하나로 지정
  };

  // 'ko-KR' 로케일을 사용하여 날짜 형식화
  let formattedDate = date.toLocaleDateString("ko-KR", options);
  const parts = formattedDate.split(" "); // 공백을 기준으로 분리
  if (parts.length > 3) {
    // 정상적으로 분리되었다면
    // 배열의 마지막 부분이 요일
    //한글버전
    const weekday = parts.pop(); // 요일 부분을 배열에서 제거하고 변수에 저장
    formattedDate = `${parts.join("")}${weekday}`; // 날짜와 요일 조합
  }

  // let formattedDate = date.toLocaleDateString("en-US", options);
  //   // 날짜와 요일 정보 분리
  //   const parts = formattedDate.split(', '); // 미국식 날짜에서는 일반적으로 날짜와 요일이 콤마와 공백으로 구분됩니다
  //   if (parts.length > 1) { // 정상적으로 분리되었다면
  //     const weekday = parts[0];  // 배열의 첫 부분이 요일
  //     const datePart = parts[1]; // 나머지 부분이 날짜
  //     formattedDate = `${datePart} (${weekday})`;  // 날짜와 요일 조합하여 재구성
  //   }

  // console.log("formmattedDate",formattedDate);
  // 괄호를 사용해 요일을 표시하도록 문자열 수정

  return formattedDate;
};

// 주말 체크 함수
export const isWeekend = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDay();
  return day === 0 || day === 6; // 0: 일요일, 6: 토요일
};

// 근무 시간 외 체크 함수
export const isOutsideWorkingHours = (
  timeString: string | null | undefined
) => {
  if (!timeString) {
    return false; // timeString이 null 또는 undefined일 때 기본값으로 false 반환
  }
  const [hours] = timeString.split(":").map(Number);
  return hours < 9 || hours > 18; // 9시 이전 또는 18시 이후
};
// userUtils.ts에서 getHighlightStyle 함수 수정
export const getHighlightStyle = (
  isWeekendLogin: boolean,
  isOutsideWorkingHours: boolean
) => {
  let highlightStyle = {};

  if (isWeekendLogin) {
    highlightStyle = isOutsideWorkingHours
      ? { color: "orange", fontWeight: "bold" }
      : { color: "pink", fontWeight: "bold" };
  } else if (isOutsideWorkingHours) {
    highlightStyle = { color: "red", fontWeight: "bold" };
  } else {
    highlightStyle = { color:"", fontWeight: "" }; // 평일 정상 시간 로그인에 대한 스타일
  }

  return highlightStyle;
};
