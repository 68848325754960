import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "@/index";
import { useSelector } from "react-redux";
import {
  fetchTotalUsersCount,
  fetchPagedUserList,
  getUniqueCompanyNames,
} from "../../redux/user/userActions";
import { Box, SelectChangeEvent, useTheme } from "@mui/material";
import UserProfileComponent from "../../components/user/UserProfileComponent";
import UserFilterComponent from "../../components/user/UserFilterComponent";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import UserListForm from "../../components/user/userListForm";
import UserListTableForm from "../../components/user/userListTableForm";

interface Company {
  companyName: string;
}

const UserListPage: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const totalUsers = useSelector((state: RootState) => state.user.totalUsers);
  const pagedUsers = useSelector((state: RootState) => state.user.pagedUsers);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // 상태 변수
  const [viewMode, setViewMode] = useState<"table" | "card">("table");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectClick, setSelectClick] = useState(""); //회사선택 스크롤을 통한 접근인가 여부.
  const [searchText, setSearchText] = useState("");
  const [companyList, setCompanyList] = useState<Company[]>([]);

  // 페이지당 아이템 수 계산
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  useEffect(() => {
    dispatch(fetchTotalUsersCount());
    dispatch(getUniqueCompanyNames())
      .then((data) => {
        setCompanyList(data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [dispatch]);

  useEffect(() => {
    const values = queryString.parse(location.search);
    setSelectedCompany((values.company as string) || "");
    setSearchText((values.search as string) || "");
    const page = parseInt((values.page as string) || "1", 10);

    dispatch(
      fetchPagedUserList({
        page: page,
        size: itemsPerPage,
        searchText: values.search as string,
        selectedCompany: values.company as string,
      })
    );
  }, [dispatch, location.search, itemsPerPage]);

  // selectedCompany나 searchText가 변경될 때 데이터 재조회
  useEffect(() => {
    console.log("company, search가 변경될떄 호출되는 use effect - 진입");
    // selectedCompany가 유효한 값일 때만 API를 호출
    if (selectedCompany && selectClick) {
      console.log(
        "company, search가 변경될떄 호출되는 use effect - selectedCompany가 있을때만 호출"
      );
      dispatch(
        fetchPagedUserList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: selectedCompany,
        })
      );
    }
  }, [selectedCompany, dispatch]);

  // 회사 선택 핸들러
  const handleCompanyChange = (event: SelectChangeEvent) => {
    const newCompany = event.target.value as string;
    setSelectedCompany(newCompany);
    setSelectClick("on");
    console.log("회사명:", event.target.value);
    console.log("기존회사명:", selectedCompany);

    // URL 업데이트와 동시에 API 호출
    const queryString = `?page=1&search=${encodeURIComponent(
      searchText
    )}&company=${encodeURIComponent(newCompany)}`;
    navigate(`/user-list${queryString}`);

    // 'None'을 선택한 경우 페이지를 1로 리셋하고, company는 빈 문자열로 설정
    if (newCompany === "") {
      navigate(`/user-list?page=1&search=${encodeURIComponent(searchText)}`);
      dispatch(
        fetchPagedUserList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: "",
        })
      );
    } else {
      // 다른 회사가 선택되면 해당 회사와 현재 검색어를 사용하여 새로운 데이터 불러오기
      dispatch(
        fetchPagedUserList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: newCompany,
        })
      );
    }
  };

  const handleSearch = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault();
    navigate(
      `/user-list?page=1&search=${encodeURIComponent(
        searchText
      )}&company=${encodeURIComponent(selectedCompany)}`
    );
    dispatch(
      fetchPagedUserList({
        size: itemsPerPage,
        searchText: searchText,
        selectedCompany: selectedCompany,
      })
    );
  };

  const handlePageChange = (pageNumber: number) => {
    const queryString = `?page=${pageNumber}&search=${encodeURIComponent(
      searchText
    )}&company=${encodeURIComponent(selectedCompany)}`;
    navigate(`/user-list${queryString}`);
    dispatch(
      fetchPagedUserList({
        page: pageNumber,
        size: itemsPerPage,
        searchText: searchText,
        selectedCompany: selectedCompany,
      })
    );
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.down(1000)]: {
            // 화면 너비가 1000px 이하일 때
            flexDirection: "column", // 방향을 세로로 변경
          },
        }}
      >
        <div className="container mt-3 " style={{ minWidth: "300px" }}>
          {/* 필터 컴포넌트 */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
          >
            <UserFilterComponent
              viewMode={viewMode}
              setViewMode={setViewMode}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              searchText={searchText}
              setSearchText={setSearchText}
              handleCompanyChange={handleCompanyChange}
              handleSearch={handleSearch}
              companyList={companyList}
            />
          </Box>
          {/* 메인 콘텐츠 */}
          <Box
            sx={
              {
                // display: "flex",
                // justifyContent: "center"
              }
            }
          >
            {/* 뷰 모드에 따라 조건부 렌더링 */}
            <div className="container mt-3" style={{ minWidth: "300px" }}>
              {viewMode === "table" ? (
                <UserListTableForm
                  pagedUsers={pagedUsers}
                  handlePageChange={handlePageChange}
                />
              ) : (
                <UserListForm
                  pagedUsers={pagedUsers}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          </Box>
        </div>
        {/* 사이드 섹션 */}
        <Box
          sx={{
            width: "300px",
            [theme.breakpoints.down(1000)]: {
              display: "none",
            },
          }}
        >
          <div
            style={{
              marginTop: "5rem",
            }}
          >
            <UserProfileComponent followersCount={totalUsers} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default UserListPage;
