import UserDetailForm from "../../components/user/userDetailForm";
import React, { Dispatch, useEffect, useState } from "react";

import { RootState } from "@/index";
import { useSelector } from "react-redux";
import UserInfoForm from "../../components/user/userInfoForm";
import { Box } from "@mui/material";
import UserLogsFilterByDate from "../../components/user/UserLogsFilterByDate";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchPagedUserLog } from "../../redux/user/userActions";
import queryString from "query-string";
import { formatISO } from "date-fns"; //

const UserDetailpage: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined); // undefined로 설정
  const [endDate, setEndDate] = useState<Date | undefined>(undefined); // undefined로 설정
  const { pagedLogs, loading, error } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();
  const userId = String(id);

  // 화면 크기에 따라 항목 수 결정
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      console.log("handle Resize 함수 작동");
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);



  //log가져오는 로직
  useEffect(() => {
    const values = queryString.parse(location.search);
    const page = parseInt((values.page as string) || "1", 10);
    const startDateStr = values.startDate as string | undefined;
    const endDateStr = values.endDate as string | undefined;
    let startDate;
    let endDate;
    if (startDateStr) {
      console.log("startDateStr:",startDateStr)
      startDate = new Date(startDateStr);
      setStartDate(startDate);
    }
  
    if (endDateStr) {
      endDate = new Date(endDateStr);
      setEndDate(endDate);
    }
    // const startDate = Array.isArray(values.startDate)
    //   ? new Date(values.startDate[0] || "")
    //   : values.startDate
    //   ? new Date(values.startDate)
    //   : undefined;

    // const endDate = Array.isArray(values.endDate)
    //   ? new Date(values.endDate[0] || "")
    //   : values.endDate
    //   ? new Date(values.endDate)
    //   : undefined;

    console.log("userId:", userId);
    console.log("page:", page);
    console.log("size:", itemsPerPage);
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    if (userId && userId !== "") {
      dispatch(
        fetchPagedUserLog(userId, page, itemsPerPage, startDate, endDate)
      );
    }
  }, [dispatch, userId, location.search, itemsPerPage]);

  const handleResetDates = () => {
    setStartDate(undefined); // undefined로 설정
    setEndDate(undefined); // undefined로 설정
    navigate(`/user-detail/${userId}?page=1`);
    
  };


  //페이지버튼 누를때
  const handlePageChange = (pageNumber: number) => {
    // const formattedStartDate = startDate
    //   ? formatISO(startDate, { representation: "date" }) // 날짜를 ISO 문자열로 포맷
    //   : undefined;
    // const formattedEndDate = endDate
    //   ? formatISO(endDate, { representation: "date" }) // 날짜를 ISO 문자열로 포맷
    //   : undefined;

    //이렇게해야 날짜만 추출하여 쿼리가 깔끔해짐 이렇게안해도 작동은함
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : undefined;
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : undefined;
      
    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);
    console.log("startDate", startDate?.toISOString());
    console.log("endDate", endDate);

    // queryString을 만들 때 undefined 체크하여 쿼리에 추가
    const queryString = `?page=${pageNumber}${
      formattedStartDate ? `&startDate=${formattedStartDate}` : ""
    }${formattedEndDate ? `&endDate=${formattedEndDate}` : ""}`;
    navigate(`/user-detail/${userId}/${queryString}`);
    //navigate로 다시 리렌더링하는데 굳이 밑에꺼 할필요가있겠니?
    // dispatch(
    //   fetchPagedUserLog(
    //     userId,
    //     pageNumber,
    //     itemsPerPage,
    //     formattedStartDate ? new Date(formattedStartDate) : undefined,
    //     formattedEndDate ? new Date(formattedEndDate) : undefined
    //   )
    // );
  };

  const handleDateChange = (newStartDate?: Date, newEndDate?: Date) => {
    if (newStartDate && newEndDate && newStartDate <= newEndDate) {
      const startIsoDate = formatISO(newStartDate, { representation: "date" });
      const endIsoDate = formatISO(newEndDate, { representation: "date" });
      console.log("startIsoDate", startIsoDate);
      console.log("endIsoDate", endIsoDate);

      // URL 쿼리스트링 업데이트 및 페이지 이동
      navigate(
        `/user-detail/${userId}?startDate=${startIsoDate}&endDate=${endIsoDate}`
      );

      // 상태 업데이트
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      // 선택적으로 에러 상태 처리나 사용자에게 알림
      alert("시작 날짜는 종료 날짜와 같거나 이전이어야 합니다.");
    }
  };

  const handleResetList = () => {
    navigate("/user-list?page=1");
  };

  console.log("UserDetailpage렌더링");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="container mt-3" style={{ minWidth: "300px" }}>
          {/*맨윗줄 유저정보랑  기간설정*/}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <UserInfoForm pagedLogs={pagedLogs}></UserInfoForm>
            <UserLogsFilterByDate
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleDateChange={handleDateChange}
              handleResetDates={handleResetDates}
            />
          </Box>
          {/* 메인컨텐츠 */}
          <Box>
            <div className="container mt-3" style={{ minWidth: "300px" }}>
              <UserDetailForm
                pagedLogs={pagedLogs}
                handlePageChange={handlePageChange}
                handleResetList={handleResetList}
              ></UserDetailForm>
            </div>
          </Box>
        </div>
        {/* side */}
        {/* <Box
          sx={{
            width: "300px",
            [theme.breakpoints.down(1000)]: {
              display: "none",
            },
          }}
        >
          <div
            style={{
              marginTop: "5rem",
            }}
          >
            
          </div>
        </Box> */}
      </Box>
    </>
  );
};

export default UserDetailpage;
