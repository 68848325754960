import DashBoardPage from "../pages/dashBoard/DashBoardPage";

const DashBoardRoutes = [
  {
    path: "/dashboard",
    component: DashBoardPage,
    allowedRoles: ["0", "1"],
  },
];

export default DashBoardRoutes;
