import {
  DELETE_USER,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  FETCH_USERLIST_FAILURE,
  FETCH_USERLIST_REQUEST,
  FETCH_USERLIST_SUCCESS,
  FETCH_PAGED_USERLIST_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  PWINIT_USER,
  PWINIT_USER_FAILURE,
  PWINIT_USER_SUCCESS,
  UserActionTypes,
  UserState,
  FETCH_TOTAL_USERS_COUNT_REQUEST,
  FETCH_TOTAL_USERS_COUNT_SUCCESS,
  FETCH_TOTAL_USERS_COUNT_FAILURE,
  FETCH_PAGED_USER_LOGS_SUCCESS,
  FETCH_PAGED_ALL_LOGS_REQUEST,
  FETCH_PAGED_ALL_LOGS_SUCCESS,
  FETCH_PAGED_ALL_LOGS_FAILURE,
} from "../../types/userTypes";

const initialState: UserState = {
  loading: false,
  user: null,
  userList: [],
  pagedUsers: null,
  userDetailLoginData: [],
  pagedLogs: null,
  error: null,
  totalUsers: 0,
};

export const userReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case FETCH_USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
        error: "",
      };
    case FETCH_PAGED_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pagedUsers: action.payload,
        error: "",
      };
    case FETCH_USERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        userList: [],
        error: action.payload,
      };
    case FETCH_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_USER_SUCCESS:
      return { ...state, loading: false, userDetailLoginData: action.payload };
    case FETCH_PAGED_USER_LOGS_SUCCESS:
      return { ...state, loading: false, pagedLogs: action.payload };
    case FETCH_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case FETCH_PAGED_ALL_LOGS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PAGED_ALL_LOGS_SUCCESS:
      return { ...state, loading: false, pagedLogs: action.payload };
    case FETCH_PAGED_ALL_LOGS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case DELETE_USER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: state.userList.filter(
          (user) => user.userId !== action.payload
        ), // 삭제된 매니저 제거
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PWINIT_USER:
      return {
        ...state,
        loading: true,
      };
    case PWINIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PWINIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_TOTAL_USERS_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOTAL_USERS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalUsers: action.payload,
      };
    case FETCH_TOTAL_USERS_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
