import {
  LoginsPerDate,
  FETCH_COUNTLOGINSPERDATE_FAILURE,
  FETCH_COUNTLOGINSPERDATE_REQUEST,
  FETCH_COUNTLOGINSPERDATE_SUCCESS,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE,
  LoginsPerDateOutOfWorkHours,
  AnomaliesPerDate,
  FETCH_COUNTANOMALIESPERDATE_REQUEST,
  FETCH_COUNTANOMALIESPERDATE_SUCCESS,
  FETCH_COUNTANOMALIESPERDATE_FAILURE,
  WeekendLoginsPerDate,
  FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST,
  FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE,
  WeekendLoginsDuringWorkHours,
  WeekendLoginsOutOfWorkHours,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE,
} from "../../types/DashBoardTypes";
import mainRequest from "../../api/mainRequest";

//  1일별 로그인
export const fetchCountLoginsPerDate = () => {
  return (dispatch: any) => {
    dispatch(fetchCountLoginsPerDateRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countLoginsPerDate", config)
      .then((response) => {
        dispatch(fetchCountLoginsPerDateSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountLoginsPerDateFailure(error.message));
      });
  };
};

export const fetchCountLoginsPerDateRequest = () => {
  return {
    type: FETCH_COUNTLOGINSPERDATE_REQUEST,
  };
};

export const fetchCountLoginsPerDateSuccess = (
  DashBoardLogs: LoginsPerDate[]
) => {
  return {
    type: FETCH_COUNTLOGINSPERDATE_SUCCESS,
    payload: DashBoardLogs,
  };
};

export const fetchCountLoginsPerDateFailure = (error: string) => {
  return {
    type: FETCH_COUNTLOGINSPERDATE_FAILURE,
    payload: error,
  };
};

// 2 일별 이상징후
export const fetchCountAnomaliesPerDate = () => {
  return (dispatch: any) => {
    dispatch(fetchCountAnomaliesPerDateRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countAnomaliesPerDate", config)
      .then((response) => {
        dispatch(fetchCountAnomaliesPerDateSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountAnomaliesPerDateFailure(error.message));
      });
  };
};

export const fetchCountAnomaliesPerDateRequest = () => {
  return {
    type: FETCH_COUNTANOMALIESPERDATE_REQUEST,
  };
};

export const fetchCountAnomaliesPerDateSuccess = (
  AnomaliesPerDate: AnomaliesPerDate[]
) => {
  return {
    type: FETCH_COUNTANOMALIESPERDATE_SUCCESS,
    payload: AnomaliesPerDate,
  };
};

export const fetchCountAnomaliesPerDateFailure = (error: string) => {
  return {
    type: FETCH_COUNTANOMALIESPERDATE_FAILURE,
    payload: error,
  };
};

// 3. 일별 근무시간외 로그인

export const FetchCountLoginsPerDateOutOfWorkHours = () => {
  return (dispatch: any) => {
    dispatch(fetchCountLoginsPerDateOutOfWorkHoursRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countLoginsPerDateOutOfWorkHours", config)
      .then((response) => {
        dispatch(fetchCountLoginsPerDateOutOfWorkHoursSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountLoginsPerDateOutOfWorkHoursFailure(error.message));
      });
  };
};


export const fetchCountLoginsPerDateOutOfWorkHoursRequest = () => {
  return {
    type: FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST,
  };
};

export const fetchCountLoginsPerDateOutOfWorkHoursSuccess = (
  LoginsPerDateOutOfWorkHours: LoginsPerDateOutOfWorkHours[]
) => {
  return {
    type: FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS,
    payload: LoginsPerDateOutOfWorkHours,
  };
};

export const fetchCountLoginsPerDateOutOfWorkHoursFailure = (error: string) => {
  return {
    type: FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE,
    payload: error,
  };
};


//4. 주말 로그인현황

export const fetchCountWeekendLoginsPerDate = () => {
  return (dispatch: any) => {
    dispatch(fetchCountWeekendLoginsPerDateRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countWeekendLoginsPerDate", config)
      .then((response) => {
        dispatch(fetchCountWeekendLoginsPerDateSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountWeekendLoginsPerDateFailure(error.message));
      });
  };
};

export const fetchCountWeekendLoginsPerDateRequest = () => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST,
  };
};

export const fetchCountWeekendLoginsPerDateSuccess = (
  WeekendLoginsPerDate: WeekendLoginsPerDate[]
) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS,
    payload: WeekendLoginsPerDate,
  };
};

export const fetchCountWeekendLoginsPerDateFailure = (error: string) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE,
    payload: error,
  };
};

//5. 주말 근무시간 내 접속자수
export const fetchCountWeekendLoginsDuringWorkHours = () => {
  return (dispatch: any) => {
    dispatch(fetchCountWeekendLoginsDuringWorkHoursRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countWeekendLoginsDuringWorkHours", config)
      .then((response) => {
        dispatch(fetchCountWeekendLoginsDuringWorkHoursSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountWeekendLoginsDuringWorkHoursFailure(error.message));
      });
  };
};

export const fetchCountWeekendLoginsDuringWorkHoursRequest = () => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST,
  };
};

export const fetchCountWeekendLoginsDuringWorkHoursSuccess = (
  WeekendLoginsDuringWorkHours: WeekendLoginsDuringWorkHours[]
) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS,
    payload: WeekendLoginsDuringWorkHours
  };
};

export const fetchCountWeekendLoginsDuringWorkHoursFailure = (error: string) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE,
    payload: error
  };
};

// 6. 주말 근무시간 외 접속자 수
export const fetchCountWeekendLoginsOutOfWorkHours = () => {
  return (dispatch: any) => {
    dispatch(fetchCountWeekendLoginsOutOfWorkHoursRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/dashboard/onlymanager/countWeekendLoginsOutOfWorkHours", config)
      .then((response) => {
        dispatch(fetchCountWeekendLoginsOutOfWorkHoursSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchCountWeekendLoginsOutOfWorkHoursFailure(error.message));
      });
  };
};

export const fetchCountWeekendLoginsOutOfWorkHoursRequest = () => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST,
  };
};

export const fetchCountWeekendLoginsOutOfWorkHoursSuccess = (
  WeekendLoginsOutOfWorkHours: WeekendLoginsOutOfWorkHours[]
) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS,
    payload: WeekendLoginsOutOfWorkHours,
  };
};

export const fetchCountWeekendLoginsOutOfWorkHoursFailure = (error: string) => {
  return {
    type: FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE,
    payload: error,
  };
};
