import { AxiosError } from "axios";
import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducers";
import {
  Company,
  DELETE_USER,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  ErrorResponse,
  FETCH_USERLIST_FAILURE,
  FETCH_USERLIST_REQUEST,
  FETCH_USERLIST_SUCCESS,
  FETCH_PAGED_USERLIST_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  PWINIT_USER,
  PWINIT_USER_FAILURE,
  PWINIT_USER_SUCCESS,
  FETCH_TOTAL_USERS_COUNT_REQUEST,
  FETCH_TOTAL_USERS_COUNT_SUCCESS,
  FETCH_TOTAL_USERS_COUNT_FAILURE,
  User,
  UserActionTypes,
  UserLastLoginData,
  UserUpdate,
  PagedResponse,
  FETCH_PAGED_USER_LOGS_SUCCESS,
  FETCH_PAGED_ALL_LOGS_SUCCESS,
  FETCH_PAGED_ALL_LOGS_FAILURE,
  FETCH_PAGED_ALL_LOGS_REQUEST,
} from "../../types/userTypes"; // userTypes.ts에서 타입들을 가져옵니다.
import mainRequest from "../../api/mainRequest";
import { toast } from "react-toastify";

export const userRegister =
  (
    userData: User,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("사원등록 try ");
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await mainRequest.post(
        "/user/onlymanager/register",
        userData,
        config
      );
      alert("사원 등록 성공");
      navigate("/user-list");
    } catch (error) {
      console.log("사원 등록 실패!");
      console.error("error: ", error);
      const axiosError = error as AxiosError; // AxiosError로 타입 캐스팅
      console.error("axiosError: ", axiosError);

      if (axiosError.response && axiosError.response.data) {
        // 에러 메시지가 있는지 확인하고 처리
        const data = axiosError.response.data as ErrorResponse;
        console.log("data", data);
        console.log("data.message", data.message);
        if ("message" in data && typeof data.message === "string") {
          if (data.message.includes("ManagerId")) {
            toast.error("존재 하지 않는 관리자 아이디 입니다.");
          } else if (data.message.includes("이메일")) {
            toast.error("이메일이 중복되었습니다.");
          } else if (data.message.includes("전화번호")) {
            toast.error("전화번호가 중복되었습니다.");
          } else {
            toast.error("사원 등록 실패");
          }
        } else {
          // 'message' 속성이 없거나 문자열이 아닌 경우
          toast.error("알 수 없는 오류가 발생했습니다.");
        }
      } else {
        // axiosError.response가 없는 경우
        toast.error("서버 응답 없음");
      }
    }
  };

export const checkUserIdDuplication =
  (userId: string) => async (dispatch: Dispatch) => {
    console.log("checkUserIdDuplication 액션 접근");
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    console.log("config.headers.Authorization", config.headers.Authorization);

    const response = await mainRequest.post(
      `/user/onlymanager/check-id/${userId}`,
      "",
      config
    );
    return response.data;
  };

export const fetchUserListRequest = () => {
  return {
    type: FETCH_USERLIST_REQUEST,
  };
};

export const fetchUserListSuccess = (userList: UserLastLoginData[]) => {
  return {
    type: FETCH_USERLIST_SUCCESS,
    payload: userList,
  };
};

export const fetchUserListPageSuccess = (
  pagedUsers: PagedResponse<UserLastLoginData> | null
) => {
  return {
    type: FETCH_PAGED_USERLIST_SUCCESS,
    payload: pagedUsers,
  };
};

export const fetchUserListFailure = (error: string) => {
  return {
    type: FETCH_USERLIST_FAILURE,
    payload: error,
  };
};

// 페이지가 안되서 지금은 안씀 - 모든 유저 가져오기
export const fetchUserList = () => {
  return (dispatch: any) => {
    dispatch(fetchUserListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/user/onlymanager/list", config)
      .then((response) => {
        dispatch(fetchUserListSuccess(response.data));
        console.log("response", response.data);
      })
      .catch((error) => {
        dispatch(fetchUserListFailure(error.message));
      });
  };
};

// 페이지별로 user 리스트 가져오기
export const fetchPagedUserList = ({
  page = 1,
  size = 12,
  searchText = "",
  selectedCompany = "",
}) => {
  return (dispatch: any) => {
    console.log("fetchPagedUserList -action 진입");
    dispatch(fetchUserListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: {
        page, // Add the page number to the request query parameters
        size,
        ...(searchText && { search: searchText }),
        ...(selectedCompany && { company: selectedCompany }),
      },
    };
    mainRequest
      .get("/user/onlymanager/list-page", config)
      .then((response) => {
        console.log(
          "action-fetchList스프링에서 가져온 reponse.data: ",
          response.data
        );
        dispatch(fetchUserListPageSuccess(response.data));
      })
      .catch((error) => {
        console.error("Error fetching user list:", error);
        dispatch(fetchUserListFailure(error.message));
      });
  };
};

export const fetchTotalUsersCountRequest = () => ({
  type: FETCH_TOTAL_USERS_COUNT_REQUEST,
});

export const fetchTotalUsersCountSuccess = (total: number) => ({
  type: FETCH_TOTAL_USERS_COUNT_SUCCESS,
  payload: total,
});

export const fetchTotalUsersCountFailure = (error: string) => ({
  type: FETCH_TOTAL_USERS_COUNT_FAILURE,
  payload: error,
});

// 모든 유저 수  세기
export const fetchTotalUsersCount = () => {
  return (dispatch: any) => {
    dispatch(fetchTotalUsersCountRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/user/onlymanager/countUsers", config)
      .then((response) => {
        console.log(
          "fetchTotalUsersCount-스프링에서 가져온 reponse.data: ",
          response.data
        );
        dispatch(fetchTotalUsersCountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalUsersCountFailure(error.message));
      });
  };
};

//페이지가 안되서 지금은 안씀 - 한 user의 모든 로그 가져오기
export const fetchUser =
  (
    userId: string
  ): ThunkAction<Promise<User>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_USER_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/user/onlymanager/detail/${userId}`,
          config
        );
        console.log("fetchUser의response.data", response.data);
        dispatch({ type: FETCH_USER_SUCCESS, payload: response.data });
        resolve(response.data);
        console.log("fetchUser액션- fetchUser 성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_USER_FAILURE, error: err.message });
        reject(err);
      }
    });
  };

// export const fetchUser = (userId: string) => {
//   return (dispatch: any) => {
//     dispatch({ type: FETCH_USER_REQUEST });
//     const config = {
//       headers: {
//         Authorization: localStorage.getItem("token"),
//       },
//     };
//     mainRequest
//       .get(`/user/onlymanager/detail/${userId}`,config)
//       .then((response) => {
//         console.log("fetchUser의response.data", response.data);
//         dispatch({ type: FETCH_USER_SUCCESS, payload: response.data });
//       })
//       .catch((error) => {
//         const err = error as Error;
//         dispatch({ type: FETCH_USER_FAILURE, error: err.message });
//       });
//   };
// };

// 페이징 처리된 한 user의 모든 로그 가져오기

export const fetchPagedUserLog =
  (
    userId: string,
    page: number = 1, // page의 기본값은 1로 설정
    size: number = 12, // size의 기본값은 12로 설정
    startDate?: Date,
    endDate?: Date
  ): ThunkAction<Promise<User>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      console.log("fetchPagedUserLog-action에 진입");
      dispatch({ type: FETCH_USER_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            page,
            size,
            startDate,
            endDate,
          },
        };
        const response = await mainRequest.get(
          `/user/onlymanager/detail-page/${userId}`,
          config
        );
        console.log("fetchPagedUserLog의response.data: ", response.data);
        dispatch({
          type: FETCH_PAGED_USER_LOGS_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
        console.log("fetchPagedUserLog액션- fetchPagedUserLog 성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_USER_FAILURE, error: err.message });
        reject(err);
      }
    });
  };

// 한 유저의 프로필정보 가져오기
export const fetchUserInfo =
  (
    userId: string
  ): ThunkAction<Promise<User>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_USER_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/user/onlymanager/detail-info/${userId}`,
          config
        );
        console.log("fetchUser의response.data", response.data);
        resolve(response.data);
        console.log("fetchUser액션- fetchUserInfo 성공");
      } catch (error) {
        const err = error as Error;
        reject(err);
      }
    });
  };
export const deleteUser =
  (
    userId: string
  ): ThunkAction<Promise<void>, RootState, unknown, UserActionTypes> => // 반환 타입 변경
  (dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      dispatch({ type: DELETE_USER });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        await mainRequest.delete(`/user/onlymanager/delete/${userId}`, config);
        dispatch({ type: DELETE_USER_SUCCESS, payload: userId });
        toast.success("사원이 삭제되었습니다!");
        resolve();
      } catch (error) {
        const err = error as Error;
        dispatch({ type: DELETE_USER_FAILURE, error: err.message });
        toast.error("사원 삭제에 실패하였습니다!");
        reject(error);
      }
    });
  };

export const updateUser =
  (
    userData: UserUpdate,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("update user-try");
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await mainRequest.put(
        "/user/onlymanager/update",
        userData,
        config
      );
      alert("update user 성공");
      // if (getState().user.isLoggedIn) {
      // }
      navigate("/user-list");
    } catch (error) {
      console.log("update user 실패!");
      console.error("error: ", error);
      const axiosError = error as AxiosError; // AxiosError로 타입 캐스팅
      console.error("axiosError: ", axiosError);

      if (axiosError.response && axiosError.response.data) {
        // 에러 메시지가 있는지 확인하고 처리
        const data = axiosError.response.data as ErrorResponse;
        console.log("data", data);
        console.log("data.message", data.message);
        if ("message" in data && typeof data.message === "string") {
          if (data.message.includes("이메일")) {
            toast.error("이메일이 중복되었습니다.");
          } else if (data.message.includes("전화번호")) {
            toast.error("전화번호가 중복되었습니다.");
          } else {
            toast.error("update user 실패");
          }
        } else {
          // 'message' 속성이 없거나 문자열이 아닌 경우
          toast.error("알 수 없는 오류가 발생했습니다.");
        }
      } else {
        // axiosError.response가 없는 경우
        toast.error("서버 응답 없음");
      }
    }
  };
export const pwInitializationUser =
  (
    userId: string
  ): ThunkAction<Promise<void>, RootState, unknown, UserActionTypes> => // 반환 타입 변경
  (dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      dispatch({ type: PWINIT_USER });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        await mainRequest.put(`/user/onlymanager/pwinit/${userId}`, "", config);
        dispatch({ type: PWINIT_USER_SUCCESS, payload: userId });
        toast.success("비밀번호가 초기화 되었습니다.!");
        resolve();
      } catch (error) {
        const err = error as Error;
        dispatch({ type: PWINIT_USER_FAILURE, error: err.message });
        toast.error("비밀번호가 초기화에 실패했습니다.");
        reject(error);
      }
    });
  };

export const getUniqueCompanyNames =
  (): ThunkAction<Promise<Company[]>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/user/onlyadmin/get-companys`,
          config
        );
        resolve(response.data);
      } catch (error) {
        const err = error as Error;
        reject(err);
      }
    });
  };


  
export const fetchPagedAllLogs =
(
  page: number = 1, // page의 기본값은 1로 설정
  size: number = 12, // size의 기본값은 12로 설정
  startDate?: Date,
  endDate?: Date,
  type?:string
): ThunkAction<Promise<User>, RootState, unknown, UserActionTypes> =>
async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    console.log("fetchPagedAllLogs - action에 진입");
    dispatch({ type: FETCH_PAGED_ALL_LOGS_REQUEST });

    try {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page,
          size,
          startDate,
          endDate,
          type,
        },
      };
      const response = await mainRequest.get(
        `/user/onlymanager/logs-page`,
        config
      );
      console.log("fetchPagedAllLogs의response.data: ", response.data);
      dispatch({
        type: FETCH_PAGED_ALL_LOGS_SUCCESS,
        payload: response.data,
      });
      resolve(response.data);
      console.log("fetchPagedAllLogs액션- fetchPagedAllLogs 성공");
    } catch (error) {
      const err = error as Error;
      dispatch({ type: FETCH_PAGED_ALL_LOGS_FAILURE, error: err.message });
      reject(err);
    }
  });
};
