import {
  LoginsPerDate,
  LoginsPerDateOutOfWorkHours,
} from "../../types/DashBoardTypes";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ArrowForward, OpenInNew, TouchApp as TouchAppIcon } from '@mui/icons-material';
interface BarChartProps {
  title: string;
  data: LoginsPerDate[] | LoginsPerDateOutOfWorkHours[] | null;
  onClick: () =>void;
}

const BarChartComponent: React.FC<BarChartProps> = ({ title, data, onClick }) => {
  const chartData = data
    ? data.map((log) => ({
        name: log.loginDate,
        value: log.loginCount, // 예시 변환
      }))
    : [];
  // console.log("data:", data);
  // console.log("chartData:", chartData);
  // 기본 색상 설정 (초록색)
  let barColor = "#82ca9d";

  if (title === "일별 이상징후 현황") {
    barColor = "#ff0000";//레드
  }
  else if(title === "일별 근무시간 외 로그인 현황"){
    barColor = "#ff0000";
  }
  else if(title === "주말 근무시간 내 로그인 현황"){
    barColor = "#FFD700";//노랑
  }
  else if(title === "주말 근무시간 외 로그인 현황"){
    barColor = "#FFA500";//주황

  }

  return (
    <div style={{ width: "100%", height: 300,cursor:"pointer" }} onClick={onClick}>
         <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
         <ArrowForward style={{ marginRight: "5px", cursor: "pointer" }} />
        <h4>{title}</h4>
        
      </div>
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis/> */}
          <YAxis domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]} /> 
          <Tooltip
            formatter={(value) => [`${value}회`, '로그인 횟수']} // Tooltip value 변경
          />
          <Bar dataKey="value" fill={barColor} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
